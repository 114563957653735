<template>
  <v-container fluid class='report-card align-self-sm-stretch justify-space-around'>
    <v-row class='d-flex justify-center card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between align-self-center'>
        <span class='report-title'> {{$t('DISTRIBUTION_DASHBOARD_TITLE')}} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
      <v-col cols='12'>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class='d-flex justify-center align-stretch font-weight-black content-value primary--text display-3'>
      <apexchart
        ref='chart'
        type='donut'
        width='500'
        :options='chartPieOptions'
        :series='series'
      />
    </v-row>
    <v-row
      class='report-footer'
    >
      <v-icon
        @click='openFilters'
      >
        mdi-filter-outline
      </v-icon>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Print Distribution',
        series: [],
        document_type: null,
        filterDialog: false,
        chartPieOptions: { labels: [] },
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        this.filterDialog = false;
        this.series = [];
        this.chartPieOptions.labels = [];
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              desc_filter: this.document_type ? `document_type = '${this.document_type}'` : null,
            },
          },
        });
        this.value = data && data.data && data.data[0] ? data.data : null;
        this.value.forEach((value) => {
          this.series.push(Number(value.x));
          this.chartPieOptions.labels.push(value.y);
        });
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        this.document_type = filter.text;
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  .report-footer {
    position: absolute;
    bottom: 10vh;
  }
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .date-picker {
    z-index: 999;
    animation: createBox 0.5s;
  }
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .active-filters-text {
    font-size: 13px;
  }
</style>
